
//const xlsaccept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';

const cbip = { InputLabelProps: { shrink: true, } };


export const reportconfig = {

  transactionreport: {
    service: {
      name: 'transaction',
      title: 'Transaction Report',
      key: 'rid',
      num: true,
      fields: ['rid', 'nam', 'shc', 'dsc', 'ati', 'atn', 'atc', 'eni', 'enm', 'enc', 'ord', 'exi', 'sts', 'ast', 'stp'],
      params: { nam: 'Name', shc: 'Code', dsc: 'Description', ati: 'Type', eni: 'Entity', sts: 'Status' },
      grid: [],
      bar: { pag: true, btn: ['print'] },
      menu: { mnu: [{ t: 'Name', v: 'nam' }, { t: 'Code', v: 'shc' }], def: 'nam' },
      eform: [
        { id: 'rpt', label: 'Report Type', type: 'fa', req: 1, ops: { placeholder: 'Select Type', strval: true, ...cbip }, cp: { t: 'lcs', s: 'reporttype' } },
        { id: 'eni', label: 'Entity', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'entity' } },
        { id: 'sdt', label: 'Start Date', type: 'df', req: 0, ops: { placeholder: 'Select Start Date' } },
        { id: 'edt', label: 'Stop Date', type: 'df', req: 0, ops: { placeholder: 'Select Stop Date' } },
        { id: 'xti', label: 'Type', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'transactiontype' } },
        { id: 'xsi', label: 'Status', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'transactionstatus' } },
        { id: 'aci', label: 'Account', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'account' } },
        // { id: 'nwi', label: 'Network', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'transactiontype' } },
        { id: 'svi', label: 'Service', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'service' } },
      ],
    },
    create: true,
    actions: { print: 'Download' },
    panels: { print: 'custom' }
  },

  // paramsreport: ':sdt,:edt,:eni,:aci,:svi,:coi,:nwi,:xsi,:xti',

  settlementreport: {
    service: {
      name: 'settlement',
      title: 'Settlement Report',
      key: 'rid',
      num: true,
      fields: ['rid', 'nam', 'shc', 'dsc', 'ati', 'atn', 'atc', 'eni', 'enm', 'enc', 'ord', 'exi', 'sts', 'ast', 'stp'],
      params: { nam: 'Name', shc: 'Code', dsc: 'Description', ati: 'Type', eni: 'Entity', sts: 'Status' },
      grid: [],
      bar: { pag: true, btn: ['print'] },
      menu: { mnu: [{ t: 'Name', v: 'nam' }, { t: 'Code', v: 'shc' }], def: 'nam' },
      eform: [
        { id: 'rpt', label: 'Report Type', type: 'fa', req: 1, ops: { placeholder: 'Select Type', strval: true, ...cbip }, cp: { t: 'lcs', s: 'reporttype' } },
        { id: 'sdt', label: 'Start Date', type: 'df', req: 1, ops: { placeholder: 'Select Start Date' } },
        { id: 'edt', label: 'Stop Date', type: 'df', req: 1, ops: { placeholder: 'Select Stop Date' } },
        { id: 'eni', label: 'Entity', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'entity' } },
        { id: 'evi', label: 'Event', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'event' } },
      ],
    },
    create: true,
    actions: { print: 'Download' },
    panels: { print: 'custom' },
  },

  customerreport: {
    service: {
      name: 'customer',
      title: 'Customer Report',
      key: 'rid',
      num: true,
      fields: ['rid', 'nam', 'shc', 'dsc', 'ati', 'atn', 'atc', 'eni', 'enm', 'enc', 'ord', 'exi', 'sts', 'ast', 'stp'],
      params: { nam: 'Name', shc: 'Code', dsc: 'Description', ati: 'Type', eni: 'Entity', sts: 'Status' },
      grid: [],
      bar: { pag: true, btn: ['print'] },
      menu: { mnu: [{ t: 'Name', v: 'nam' }, { t: 'Code', v: 'shc' }], def: 'nam' },
      eform: [
        { id: 'rpt', label: 'Report Type', type: 'fa', req: 1, ops: { placeholder: 'Select Type', strval: true, ...cbip }, cp: { t: 'lcs', s: 'reporttype' } },
        { id: 'sdt', label: 'Start Date', type: 'df', req: 1, ops: { placeholder: 'Select Start Date' } },
        { id: 'edt', label: 'Stop Date', type: 'df', req: 1, ops: { placeholder: 'Select Stop Date' } },
        { id: 'eni', label: 'Entity', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'entity' } },
        { id: 'cti', label: 'Type', type: 'fa', req: 0, ops: {}, cp: { t: 'bcs', s: 'customertype' } }
      ],
    },
    create: true,
    actions: { print: 'Download' },
    panels: { print: 'custom' },
  },

}
