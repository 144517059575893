import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import pages from '../../app/services';

const miniDrawerWidth = 60;

const styles = (theme: Theme): any => ({
  hide: {
    display: 'none',
  },
  content: {
    marginLeft: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  contentShift: {
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${miniDrawerWidth}px)`,
    },
    width: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

class Content extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      page: props.page,
      count: 0,
      params: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.page !== nextProps.page ||
      this.props.page === 'search' ||
      this.props.page === 'kanban'
    ) {
      this.setState({ page: nextProps.page });
    }
    //else this.setState({page: nextProps.page});
  }

  getPrivs = () => {
    const { page, userData } = this.props;
    const { pv } = userData;
    const pvs = pv.filter((v: any) => v.acf === page);
    //console.log('pvs:'.pvs);
    return pvs;
  };

  getActions = (file: any) => (sts: any) => {
    const pvs = this.getPrivs();
    //const pvset:any = {};
    const pvlist = pvs.reduce((acc: any, c: any) => {
      //if(sts === 0 && !['add','view'][c.bfn]) return acc;
      if (c.sts === 1) {
        acc.push(c.bfn);
        //pvset[c.bfn] = c.btx;
      }
      return acc;
    }, []);
    //console.log('file:',file,',sts:',sts,'pva:',pva);
    return pvlist; //{pvlist,pvset};
  };

  getPrivActions = () => {
    const pvs = this.getPrivs();
    const pvset: any = {};
    const pvlist = pvs.reduce((acc: any[], c: any) => {
      if (c.sts === 1) {
        acc.push(c.bfn);
        pvset[c.bfn] = c.btx;
      }
      return acc;
    }, []);
    return { pvlist, pvset };
  };

  setPage = (page: any) => this.setState({ page: page });

  setHome = () => this.setState({ page: 'dashboard' });

  setParam = (k: string, v: any) =>
    this.setState({ params: { ...this.state.params, [k]: v } });

  unsetParam = (k: string) =>
    this.setState({ params: { ...this.state.params, [k]: null } });

  resetParams = () => this.setState({ params: {} });

  onParam = (action: string) => (k: string, v: any) => {
    if (action === 'set') this.setParam(k, v);
    else if (action === 'unset') this.unsetParam(k);
    else if (action === 'reset') this.resetParams();
    else return;
  };

  render() {
    const {
      userData,
      code,
      navDrawerOpen,
      search,
      query,
      startSearch,
      stopSearch,
      showNotification,
      handleSetContent,
      cstyles,
      classes,
      title,
    } = this.props;
    //const {page,params} = this.state;
    const { page } = this.state;
    const { pvlist, pvset } = this.getPrivActions();
    return (
      <main
        className={classNames(
          classes.content,
          navDrawerOpen && classes.contentShift
        )}
      >
        <div className={classes.toolbar} />
        <div style={cstyles}>
          {React.cloneElement(page in pages ? pages[page] : pages.main, {
            title: title || page,
            page: page,
            service: page,
            search: search,
            query: query,
            rowActions: this.getActions(page), //pvlist, //
            actionSet: pvset,
            setHome: this.setHome,
            setPage: this.setPage,
            onParam: this.onParam,
            startSearch: startSearch,
            stopSearch: stopSearch,
            showNotification: showNotification,
            handleSetContent: handleSetContent,
            merchantCode: this.props.merchantCode,
            code: code,
            userData: userData,
          })}
        </div>
      </main>
    );
  }
}

Content.propTypes = {
  styles: PropTypes.object,
  showNotification: PropTypes.func,
  page: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Content);
