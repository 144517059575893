import { baseconfig } from './baseconfig';
import { basicconfig } from './basicconfig';
import { serviceconfig } from './serviceconfig';
import { customconfig } from './customconfig';
import { entityconfig } from './entityconfig';
import { codeconfig } from './codeconfig';
import { reportconfig } from './reportconfig';

const configs: any = { baseconfig, ...basicconfig, ...codeconfig, ...entityconfig, ...serviceconfig, ...reportconfig };

const getConfig = (service: string) => () => {
  var rtn = Object.assign({}, baseconfig, (configs[service] || {}));
  return rtn;
}

const setConfig = (config: any) => {
  var rtn = Object.assign({}, baseconfig, config);
  return rtn;
}

const getCustomConfig = (service: string) => () => {
  var rtn = Object.assign({}, baseconfig, (customconfig[service] || {}));
  return rtn;
}

const setCustomConfig = (config: any) => {
  console.log('customconfig', customconfig);
  console.log('config', config);
  var rtn = Object.assign({}, baseconfig, config);
  return rtn;
}

export { bcs as appbcs, lcs as applcs } from './combo';

export { getCustomConfig, setCustomConfig };

export { configs, getConfig, setConfig };

export default getConfig;
