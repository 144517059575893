import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { pink, green, grey, purple } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClosableBar } from '../../components/dialogs';
import { Download, downloadFile } from '../../ajax';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon } from '../../components/icons';
import { clone } from '../../core/utils';
import { formatDate } from '../../components/renderers';
import { create, isValid } from '../../components/form';
import { extendedstyles } from '../appstyles';

const extrastyles = (theme: Theme) => ({
  root: {
    width: '100%',
  },
  titlebar: {
    padding: 0,
    backgroundColor: theme.palette.success.light,
  },
  summary: {
    alignItems: 'space-between',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  topheading: {
    fontWeight: 'bold',
  },
  close: {
    flexBasis: '33.33%',
    align: 'right',
  },
  details: {
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  column: {
    flexBasis: '33.33%',
  },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  pdficon: {
    margin: theme.spacing(2),
    color: pink['500'],
  },
  csvicon: {
    margin: theme.spacing(2),
    color: purple['500'],
  },
  jsonicon: {
    margin: theme.spacing(2),
    color: green['800'],
  },
  zipicon: {
    margin: theme.spacing(2),
    color: grey['500'],
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink['500'],
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
});

const styles = extendedstyles(extrastyles);

class ReportForm extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      conf: {},
      views: [],
      count: 0,
      open: true,
      valid: false,
      params: { s: props.service, a: 'report' },
      data: {},
      datasets: null,
      dataset: null,
    };
  }

  formconfig = () => {
    const cbip = { InputLabelProps: { shrink: true } };
    const config = {
      service: {
        name: 'dataform',
        title: 'Generate Report',
        eform: [
          {
            id: 'rpt',
            label: 'Report Type',
            type: 'fa',
            req: 1,
            ops: { placeholder: 'Select Type', strval: true, ...cbip },
            cp: { t: 'lcs', s: 'reporttype' },
          },
          {
            id: 'fmt',
            label: 'Report Format',
            type: 'fa',
            req: 1,
            ops: { placeholder: 'Select Format', strval: true, ...cbip },
            cp: { t: 'lcs', s: 'format' },
          },
          {
            id: 'sdt',
            label: 'Start Date',
            type: 'df',
            req: 1,
            ops: { placeholder: 'Select Start Date' },
          },
          {
            id: 'edt',
            label: 'Stop Date',
            type: 'df',
            req: 1,
            ops: { placeholder: 'Select Stop Date' },
          },
        ],
      },
      create: true,
      actions: { add: 'Print' },
      panels: { add: 'simple' },
    };
    return config;
  };

  setValid = (data: any) => {
    const { config: conf } = this.props;
    const config = conf || this.formconfig();
    const valid = isValid(config, data);
    //this.props.setValid(valid);
    this.setState({ valid });
  };

  // onChange = (name:any) => (value:any) => {
  //   const {data} = this.props;
  //   const newdata = {...data.data,[name]:value};
  //   this.setValid(newdata);
  //   this.props.setItem('data','data',newdata);
  // }

  onChange = (name: any) => (value: any) => {
    console.log('form.onChange:', name, value);
    const { data } = this.state;
    const newdata = { ...data, [name]: value };
    this.setValid(newdata);
    this.setState({ data: newdata });
    //this.setState((state:any) => ({params: {...state.params,[name]:value}}));
  };

  makeform = (conf: any) => {
    const config = conf || this.formconfig();
    console.log('makeform.config:', config);

    const { record, classes, extras } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(
      mode,
      sdata,
      record,
      formatters,
      onChange,
      classes,
      extras || {}
    );
    //const form = this.columnlayout(formitems,classes);
    const title = sdata.title;
    return { title, form };
  };

  onClick = (mode: any) => async (event: any) => {
    try {
      //var btn = event.target;
      console.log('report mode:', mode);
      const { config, page, action, record } = this.props;
      const { service: svc } = config || {};
      const { name: sname } = svc || {};
      const { params: sparams, data } = this.state;
      console.log('print action:', page, sparams, action, record, sname);
      const name = page || '';
      const params = { ...sparams, ...data, s: sname, a: 'report' };
      console.log('print download:', mode, params, name, data, record);
      await downloadFile(mode, params, name, data, record);
    } catch (error) {
      console.log('report.handle.failed', error);
      const errormessage = `Error: ${error}`;
      this.props.showNotification(errormessage);
    }
  };

  onExpChange = (event: any, expanded: boolean) => {
    if (!expanded) this.setState({ open: false });
  };

  onClose = (event: any) => {
    this.setState({ open: false });
  };

  render() {
    const { config, classes } = this.props;
    const { title, form } = this.makeform(config);
    const { open, valid } = this.state;
    return (
      <Dialog open={open}>
        <DialogTitle id='window-dialog-title' className={classes.titlebar}>
          <ClosableBar onClose={this.onClose}>{title}</ClosableBar>
        </DialogTitle>
        <DialogContent className={classes.dialogcontent}>
          <div className={classes.root}>
            <Accordion defaultExpanded onChange={this.onExpChange}>
              <AccordionDetails className={classes.details}>
                <div className={classes.root}>
                  {/* <div> */}
                  {form}
                  {/* </div> */}
                </div>
              </AccordionDetails>
              <Divider />
              <AccordionActions>
                {/* <div className={classes.iconsroot}> */}
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<PDFIcon titleAccess='PDF Download' />}
                  className={classes.pdficon}
                  disabled={!valid}
                  onClick={this.onClick('pdf')}
                >
                  PDF
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<CSVIcon titleAccess='CSV Download' />}
                  className={classes.csvicon}
                  disabled={!valid}
                  onClick={this.onClick('csv')}
                >
                  CSV
                </Button>
                <Button
                  variant='contained'
                  color='success'
                  startIcon={<JSONIcon titleAccess='JSON Download' />}
                  className={classes.jsonicon}
                  disabled={!valid}
                  onClick={this.onClick('json')}
                >
                  JSON
                </Button>
                <Button
                  variant='contained'
                  color='warning'
                  startIcon={<ZIPIcon titleAccess='ZIP Download' />}
                  className={classes.zipicon}
                  disabled={!valid}
                  onClick={this.onClick('zip')}
                >
                  ZIP
                </Button>
                {/* </div> */}
              </AccordionActions>
            </Accordion>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  handleClick = (name: any) => () => {
    //var btn = event.target;
    var { data, record } = this.props;
    var d = new Download();
    d.downloadFile(name, data, record);
  };

  renderDownload() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={classnames(classes.heading, classes.topheading)}
            >
              Downloads
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <div className={classes.iconsroot}>
                <Button
                  variant='contained'
                  className={classes.customicon}
                  onClick={this.handleClick('pdf')}
                >
                  <PDFIcon titleAccess='PDF Download' />
                </Button>
                <Button
                  variant='contained'
                  className={classes.customicon}
                  onClick={this.handleClick('csv')}
                >
                  <CSVIcon titleAccess='CSV Download' />
                </Button>
                <Button
                  variant='contained'
                  className={classes.customicon}
                  onClick={this.handleClick('json')}
                >
                  <JSONIcon titleAccess='JSON Download' />
                </Button>
                <Button
                  variant='contained'
                  className={classes.customicon}
                  onClick={this.handleClick('zip')}
                >
                  <ZIPIcon titleAccess='ZIP Download' />
                </Button>
              </div>
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withStyles(styles)(ReportForm);
