//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//colors
import Grid from '@mui/material/Grid';
//import {PageBase} from '../../components/site';
import { extendedstyles } from '../appstyles';

//siblings
import { setConfig } from '../../core';
import ReportForm from './ReportForm';

/**
 * master theme styling
 */
//const styles = (theme:Theme) => servicestyle(theme,pink,'white');
const extrastyles = (theme: Theme) => ({});

const styles = extendedstyles(extrastyles);

class ReportBase extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;

  render() {
    //const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const { classes, theme, configuration, ...others } = this.props;
    const { user } = this.props;

    const reportconfig = Object.assign({}, configuration());
    //console.log('extraconfig',service,extraconfig);
    const config = setConfig(reportconfig); //

    return (
      // <PageBase navigation="Application / Report">
      <div className={classes.root}>
        {/* <h3 className={classes.navigation}>{this.props.userData.bd.enm}</h3> */}
        <Grid container spacing={theme.breakpoints.up('sm') ? 3 : 1}>
          <Grid item xs={12} sm={6}>
            <ReportForm
              action={'report'}
              record={{}}
              data={[]}
              user={user || ''}
              config={config}
              {...others}
            />
          </Grid>
        </Grid>
      </div>
      // </PageBase>
    );
  }
}

ReportBase.propTypes = {
  service: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  showNotification: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ReportBase);
