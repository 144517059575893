//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
//siblings
import { getConfig } from '../config';
import { ServiceBase } from '../../components/service';
import { extendedstyles } from '../appstyles';

import { ReportBase, getReport } from '../reports';

const extrastyles = (theme: Theme) => ({});

const styles = extendedstyles(extrastyles);

class Main extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;

  isCustomPage(page: any) {
    return false;
  }

  renderCustom() {
    console.log('In Main.render()');
    const {
      page,
      service,
      code,
      showNotification,
      setPage,
      setHome,
      classes,
      theme,
      ...others
    } = this.props;
    const title = 'Main Records';
    const configuration = getConfig(service);
    const xservice = React.cloneElement(<ServiceBase />, {
      title,
      page,
      service,
      code,
      configuration,
      setPage,
      setHome,
      showNotification,
      classes,
      theme,
      ...others,
    });
    return xservice;
  }

  isReportPage(page: any) {
    const rep = getReport(page);
    return rep !== undefined;
  }

  renderReport() {
    const {
      page,
      service,
      code,
      showNotification,
      setPage,
      setHome,
      classes,
      theme,
      ...others
    } = this.props;
    console.log(`In Main.Report.render() ${service}`);
    const title = 'Main Records';
    const configuration = getConfig(service);
    console.log(`In Main.Report.render().conf ${configuration}`);
    const rservice = React.cloneElement(<ReportBase />, {
      title,
      page,
      service,
      code,
      configuration,
      setPage,
      setHome,
      showNotification,
      classes,
      theme,
      ...others,
    });
    return rservice;
  }

  renderDefault() {
    console.log('In Main.render()');
    console.log('the page', this.props);
    const {
      page,
      service,
      code,
      showNotification,
      setPage,
      setHome,
      classes,
      theme,
      ...others
    } = this.props;
    const title = 'Main Records';
    const configuration = getConfig(service);
    const xservice = React.cloneElement(<ServiceBase />, {
      title,
      page,
      service,
      code,
      configuration,
      setPage,
      setHome,
      showNotification,
      classes,
      theme,
      ...others,
    });
    return xservice;
  }

  render() {
    const { page, service } = this.props;
    if (this.isCustomPage(page)) {
      return this.renderCustom();
    } else if (this.isReportPage(page)) {
      return this.renderReport();
    } else {
      return this.renderDefault();
    }
  }
}

Main.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(Main);
