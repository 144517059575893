// import React from 'react';
// import ReportForm from './ReportForm';
// import ActivityReport from './oldActivity';
// import AgentReport from './oldAgent';
// import ComplianceReport from './oldCompliance';
// import SettlementReport from './oldSettlement';
// import TransactionReport from './oldTransaction';
import ReportBase from './ReportBase';
import { reportconfig } from '../config/reportconfig';

// ./addpriv.sh "Activity Report" 281 jb Reports REPO 126 activityreport VIEW _repo.sql
// ./addpriv.sh "Events Report" 282 jc Reports REPO 126 eventreport VIEW _repo.sql
// ./addpriv.sh "Request Report" 283 jd Reports REPO 126 requestreport VIEW _repo.sql
// ./addpriv.sh "Review Report" 284 je Reports REPO 126 reviewreport VIEW _repo.sql
// ./addpriv.sh "Compliance Report" 285 jf Reports REPO 126 compliancereport VIEW _repo.sql
// ./addpriv.sh "Performance Report" 286 jg Reports REPO 126 performancereport VIEW _repo.sql
// ./addpriv.sh "Notification Report" 287 jh Reports REPO 126 notificationreport VIEW _repo.sql
// ./addpriv.sh "Customer Report" 288 ji Reports REPO 126 customerreport VIEW _repo.sql

//export ReportBase from './ReportBase';

export { ReportBase };

export const findReport = (service: string) => {
  const reports: any = reportconfig;
  return reports[service];
};

export const getReport = (service: string) => {
  const reports: any = reportconfig;
  return reports[service];
};

// const reports = {
//   report: <ReportForm />,
//   activity: <ActivityReport />,
//   agent: <AgentReport />,
//   compliance: <ComplianceReport />,
//   settlement: <SettlementReport />,
//   transaction: <TransactionReport />,
// };
// export { reports, ReportForm, ActivityReport, AgentReport, ComplianceReport, SettlementReport, TransactionReport };

// //const defaults = { views, ResourceView, ResultView };

// //export default defaults;

// const reportlist:any = {
//   activityreport:{
//     print:ActivityReport,
//   },
//   agentreport:{
//     print:AgentReport,
//   },
//   compliancereport:{
//     print:ComplianceReport
//   },
//   settlementreport:{
//     print:SettlementReport,
//   },
//   transactionreport:{
//     print:TransactionReport,
//   }
// }
// //TODO: create single card error wizard as default using try/catch
// const findReport = (service:string) => (action:string) => {
//   return reportlist[service][action];
// }

// const getReport = (service:string,action:string) => {
//   return reportlist[service][action];
// }

// export {findReport,getReport};

// export default reportlist;
